import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminLayout from '../layout/AdminLayout'
import AdminEducationLayout from '../layout/AdminEducationLayout'
import SchoolLayout from '../layout/SchoolLayout'
import SchoolEducationLayout from '../layout/SchoolEducationLayout'
import AdminScriptLayout from '../layout/AdminScriptLayout'
import AdminTrainLayout from '../layout/AdminTrainLayout'
import AdminTaskLayout from "../layout/AdminTaskLayout";
import AdminHelpLayout from "../layout/AdminHelpLayout";
import StudentLayout from "../layout/StudentLayout";
import TeacherLayout from "../layout/TeacherLayout";
import CompanyLayout from "../layout/CompanyLayout";
import StudentShortLayout from "../layout/StudentShortLayout";
import TeacherShortLayout from '../layout/TeacherShortLayout';
import CompanyShortLayout from '../layout/CompanyShortLayout';
import StudentScriptLayout from '../layout/StudentScriptLayout';
import StudentDataLayout from '../layout/StudentDataLayout';
import CompanyViewLayout from '../layout/CompanyViewLayout';
import AdminScriptViewLayout from '../layout/AdminScriptViewLayout';
import GoodsManageLayout from "../layout/GoodsManageLayout";
import AdminTrainViewLayout from '../layout/AdminTrainViewLayout';
Vue.use(VueRouter)
  const routes = [
  {
    path: '/admin',
    redirect: '/admin/education/index',
    component: AdminLayout,
    name:'admin',
    children: [
        {
        path: '/admin/education',
        name: 'adminEducational',
        component: AdminEducationLayout,
        children: [
            {
                path: '/admin/education/index',
                name: 'adminEducationIndex',
                component: () => import('../views/admin/EducationManage.vue'),
                meta: {
                    title: '学院管理'
                }
            },{
                path: '/admin/education/administrator',
                name: 'adminEducationAdministratorManage',
                component: () => import('../views/admin/AdministratorManage.vue'),
                meta: {
                    title: '管理员管理'
                }
            },{
                path: '/admin/education/enterprise',
                name: 'adminEducationEnterprise',
                component: () => import('../views/admin/EnterpriseManage.vue'),
                meta: {
                    title: '企业管理'
                }
            },
        ]
    },
        // 总管理员--脚本
        {
            path: '/admin/script',
            name: 'adminScript',
            component: AdminScriptLayout,
            children: [
                {
                    path: '/admin/script/classification',
                    name: 'adminScriptClassification',
                    component: () => import('../views/admin/ClassificationManage.vue'),
                    meta: {
                        title: '脚本管理'
                    }
                },
                {
                    path: '/admin/script/index',
                    name: 'scriptIndex',
                    component: AdminScriptViewLayout,
                    children: [

                        {
                            path: '/admin/script/index',
                            name: 'adminScriptIndex',
                            component: () => import('../views/admin/ScriptManage.vue'),
                            meta: {
                                title: '脚本创作'
                            }
                        },
                        {
                            path: '/admin/script/view/creation',
                            name: 'adminViewScriptCreation',
                            component: () => import('../views/admin/adminViewScriptCreation'),
                            meta: {
                                title: '查看脚本'
                            }
                        },

                    ]
                },
            ]
        },
        // 总管理员--实训管理
        {
            path: '/admin/train',
            name: 'adminTrain',
            component: AdminTrainLayout,
            children: [
                {
                    path: '/admin/train/classification',
                    name: 'adminTrainClassification',
                    component: () => import('../views/admin/TrainClassManage.vue'),
                    meta: {
                        title: '实训分类'
                    }
                },
                {
                    path: '/admin/train/list',
                    name: 'adminTrainList',
                    component: () => import('../views/admin/TrainList.vue'),
                    meta: {
                        title: '实训列表'
                    }
                },
                {
                    path: '/admin/train/detail',
                    name: 'adminTrainDetail',
                    component: () => import('../views/admin/TrainDetail.vue'),
                    meta: {
                        title: '实训详情'
                    }
                },
                {
                    path: '/admin/train/createtrain',
                    name: 'adminCreateTrain',
                    component: () => import('../views/admin/CreateTrain.vue'),
                    meta: {
                        title: '创建实训'
                    }
                },
                // {
                //     path: '/admin/train/list',
                //     name: 'trainList',
                //     component: AdminTrainViewLayout,
                //     children: [
                //
                //         {
                //             path: '/admin/train/list',
                //             name: 'adminTrainList',
                //             component: () => import('../views/admin/TrainList.vue'),
                //             meta: {
                //                 title: '实训列表',
                //                 tabName: 'adminTrainList'
                //             }
                //         },
                //         {
                //             path: '/admin/train/detail',
                //             name: 'adminTrainDetail',
                //             component: () => import('../views/admin/TrainDetail.vue'),
                //             meta: {
                //                 title: '实训详情',
                //                 tabName: 'adminTrainList'
                //             }
                //         },
                //
                //     ]
                // },
            ]
        },
        // 总管理员--任务
        {
            path: '/admin/task',
            name: 'adminTask',
            component: AdminTaskLayout,
            children: [
                {
                    path: '/admin/task/classifies',
                    name: 'adminTaskClassifies',
                    component: () => import('../views/admin/ClassifiesManage.vue'),
                    meta: {
                        title: '分类管理'
                    }
                },
                {
                    path: '/admin/task/index',
                    name: 'adminTaskIndex',
                    component: () => import('../views/admin/TaskManage.vue'),
                    meta: {
                        title: '任务管理'
                    }
                }
            ]
        },
        // 总管理员--商品管理
        {
            path: '/admin/goodsManage',
            name: 'adminGoodsManage',
            component: GoodsManageLayout,
            children: [
                {
                    path: '/admin/goodsManage/promote',
                    name: 'adminGoodsManagePromote',
                    component: () => import('../views/admin/GoodsPromote'),
                    meta: {
                        title: '商品推广',
                        tabName: 'adminGoodsManagePromote',
                    }
                },
                {
                    path: '/admin/goodsManage/list',
                    name: 'adminGoodsManageList',
                    component: () => import('../views/admin/GoodsManage'),
                    meta: {
                        title: '商品列表',
                        tabName: 'adminGoodsManageList',
                    }
                },
                {
                    path: '/admin/goodsManage/create',
                    name: 'adminGoodsManageCreate',
                    component: () => import('../views/admin/GoodsCreate'),
                    meta: {
                        title: '添加商品',
                        tabName: 'adminGoodsManageList',
                    }
                },
            ]
        },
        // 总管理员--财务
        // {
        //     path: '/admin/finance',
        //     name: 'adminFinance',
        //     component: AdminFinanceLayout,
        //     children: [
        //         {
        //             path: '',
        //             name: 'adminTaskClassifies',
        //             component: () => import('../views/admin/ClassifiesManage.vue'),
        //             meta: {
        //                 title: '分类管理'
        //             }
        //         },
        //     ]
        // },
        // 总管理员--帮助中心
        {
            path: '/admin/help',
            name: 'adminHelp',
            component: AdminHelpLayout,
            children: [
                {
                    path: '/admin/help/classifies',
                    name: 'adminHelpClassifies',
                    component: () => import('../views/admin/HelpClassifiesManage.vue'),
                    meta: {
                        title: '分类管理'
                    }
                },
                {
                    path: '/admin/help/index',
                    name: 'adminHelpIndex',
                    component: () => import('../views/admin/HelpCenter.vue'),
                    meta: {
                        title: '帮助管理'
                    }
                },
                {
                    path: '/admin/help/video',
                    name: 'adminHelpVideo',
                    component: () => import('../views/admin/HelpVideo.vue'),
                    meta: {
                        title: '视频帮助'
                    }
                }
            ]
        },
        {
            path:'/admin/announcement',
            name:'adminAnnouncement',
            component: () => import('../views/admin/AnnouncementManage.vue'),
            meta: {
                title: '公告管理'
            }
        },
        // 总管理员--创建帮助
        {
            path: '/admin/help/create',
            name: 'createHelp',
            component: () => import('../components/helpCenter/CreateHelp.vue'),
            meta: {
                title: '创建帮助'
            }
        },
        // 总管理员--帮助详情
        {
            path: '/admin/help/detail',
            name: 'helpDetail',
            component: () => import('../components/helpCenter/HelpDetail.vue'),
            meta: {
                title: '帮助详情'
            }
        }
    ]
  },
  {
      path:'/data/spectaculars',
      name:'dataSpectaculars',
      component: () => import('../views/spectaculars/dataSpectaculars'),
      meta: {
          title: '数据看板'
      }
  },
  {
      path:'/school',
      redirect: '/school/education/index',
      component: SchoolLayout,
      name: 'teacher',
      children: [
          {
              path: '/school/education/index',
              name: 'schoolEducation',
              component: SchoolEducationLayout,
              children: [
                  {
                      path: '/school/education/index',
                      name: 'schoolEducationIndex',
                      component: () => import('../views/school/EducationManage'),
                      meta: {
                          title: '专业管理'
                      }
                  },
                  {
                      path: '/school/education/teacher',
                      name: 'schoolEducationTeacher',
                      component: () => import('../views/school/TeacherManage'),
                      meta: {
                          title: '教师管理'
                      }
                  },
                  {
                      path: '/school/education/class',
                      name: 'schoolEducationClass',
                      component: () => import('../views/school/ClassManage'),
                      meta: {
                          title: '班级管理'
                      }
                  },
                  {
                      path: '/school/education/student',
                      name: 'schoolEducationStudent',
                      component: () => import('../views/school/StudentManage'),
                      meta: {
                          title: '学生管理'
                      }
                  },
              ]
          },
      ]

  },
  {
      path: '/',
      name: 'Login',
      component: () => import('../views/student/Login.vue'),
      meta: {
          title: '学生端登录'
      }
  },
  {
      path: '/admin/login',
      name: 'adminLogin',
      component: () => import('../views/Login.vue'),
      meta: {
          title: '管理员端登录'
      }
  },
  {
      path: '/teacher',
      redirect: '/teacher/login'
  },
  {
      path:'/teacher/login',
      name:'teacherLogin',
      component: () => import('../views/teacher/Login.vue'),
      meta: {
          title:'教师端登录'
      }
  },
      {
          path:'/company/login',
          name:'companyLogin',
          component: () => import('../views/company/Login.vue'),
          meta: {
              title:'企业端登录'
          }
      },
      {
          path: '/company',
          redirect: '/company/login'
      },
      {
          path: '/student',
          redirect: '/student/shortvideo',
          component: StudentLayout,
          name: 'student',
          children: [
              {
                  path: '/student/account/index',
                  name: 'studentAccount',
                  component: StudentShortLayout,
                  children: [
                      {
                          path: '/student/works/index',
                          name: 'studentWorksIndex',
                          component: () => import('../views/student/creativecenter/WorkBench'),
                          meta: {
                              title: '工作台'
                          }
                      },
                      {
                          path: '/student/account/index',
                          name: 'studentAccountIndex',
                          component: () => import('../views/student/creativecenter/AccountManage'),
                          // component: () => import('../views/student/creativecenter/AccountManage_new'),
                          meta: {
                              title: '账号管理'
                          }
                      },
                      {
                          path: '/student/interactive/index',
                          name: 'studentInteractiveIndex',
                          component: () => import('../views/student/creativecenter/InteractiveManage'),
                          meta: {
                              title: '互动管理'
                          }
                      },
                      {
                          path: '/student/data/details',
                          name: 'studentDataDetails',
                          component: () => import('../views/student/creativecenter/DataDetails'),
                          meta: {
                              title: '账号详情'
                          }
                      },
                      {
                          path: '/student/data/finishTask',
                          name: 'studentDataFinishTask',
                          component: () => import('../views/student/creativecenter/FinishTask'),
                          meta: {
                              title: '完成任务'
                          }
                      },
                      {
                          path: '/student/cultural/creation/details',
                          name: 'studentCulturalCreationDetails',
                          component: () => import('../views/student/creativecenter/CulturalCreationDetails'),
                          meta: {
                              title: '文创详情'
                          }
                      },
                      {
                          path: '/student/graphic/creation',
                          name: 'studentGraphicCreation',
                          component: () => import('../views/student/creativecenter/GraphicCreation1'),
                          meta: {
                              title: '图文创作'
                          }
                      },
                      {
                          path: '/student/graphic/index',
                          name: 'studentGraphicIndex',
                          component: () => import('../views/student/creativecenter/GraphicIndex'),
                          meta: {
                              title: '图文管理'
                          }
                      },
                      // {
                      //     path: '/student/graphic/creation1',
                      //     name: 'studentGraphicCreation',
                      //     component: () => import('../views/student/creativecenter/GraphicCreation'),
                      //     meta: {
                      //         title: '图文创作1'
                      //     }
                      // },
                      {
                          path: '/student/video/management',
                          name: 'studentVideoManagement',
                          component: () => import('../views/student/creativecenter/VideoManagement'),
                          meta: {
                              title: '视频管理'
                          }
                      },
                      {
                          path: '/student/live/management',
                          name: 'studentLiveManagement',
                          component: () => import('../views/student/creativecenter/LiveManagement'),
                          meta: {
                              title: '直播管理'
                          }
                      },
                      {
                          path: '/student/video/publish',
                          name: 'studentVideoPublish',
                          component: () => import('../views/student/creativecenter/VideoPublish'),
                          meta: {
                              title: '视频发布'
                          }
                      },
                      // {
                      //     path: '/student/short/index',
                      //     name: 'studentShortIndex',
                      //     component: () => import('../views/student/creativecenter/ShortVideoManage'),
                      //     meta: {
                      //         title: '短视频创作'
                      //     }
                      // },
                      {
                          path: '/student/video/clip/Index',
                          name: 'studentVideoClipIndex',
                          component: StudentScriptLayout,
                          children: [
                              {
                                  path: '/student/video/clip',
                                  name: 'studentVideoClip',
                                  component: () => import('../views/student/creativecenter/VideoClip'),
                                  meta: {
                                      title: '视频剪辑'
                                  }
                              },
                              {
                                  path: '/student/video/editor',
                                  name: 'studentVideoEditor',
                                  component: () => import('../views/student/creativecenter/VideoEditor'),
                                  meta: {
                                      title: '云剪辑'
                                  }
                              },
                          ]
                      },
                      {
                          path: '/student/dataAnalysis/index',
                          name: 'studentDataAnalysis',
                          component: StudentDataLayout,
                          children: [
                              {
                                  path: '/student/dataAnalysis/index',
                                  name: 'studentDataAnalysisIndex',
                                  component: () => import('../views/student/creativecenter/DataAnalysis'),
                                  meta: {
                                      title: '数据管理'
                                  }
                              },
                              {
                                  path: '/student/dataAnalysis/create',
                                  name: 'studentDataAnalysisCreate',
                                  component: () => import('../views/student/creativecenter/CreateDataAnalysis'),
                                  meta: {
                                      title: '创建数据'
                                  }
                              },
                              {
                                  path: '/student/dataAnalysis/detail',
                                  name: 'studentDataAnalysisDetail',
                                  component: () => import('../views/student/creativecenter/DataAnalysisDetail'),
                                  meta: {
                                      title: '数据分析详情'
                                  }
                              },
                          ]
                      },
                      {
                          path: '/student/productPromotion/index',
                          name: 'studentProductPromotion',
                          component: StudentDataLayout,
                          children: [
                              {
                                  path: '/student/productPromotion/index',
                                  name: 'studentProductPromotionIndex',
                                  component: () => import('../views/student/creativecenter/ProductPromotion'),
                                  meta: {
                                      title: '商品推广'
                                  }
                              },
                              {
                                  path: '/student/productPromotion/PIDTao',
                                  name: 'studentProductPromotionPIDTao',
                                  component: () => import('../views/student/creativecenter/PIDTaoTutorial'),
                                  meta: {
                                      title: '获取淘宝PID'
                                  }
                              },
                              {
                                  path: '/student/productPromotion/PIDPdd',
                                  name: 'studentProductPromotionPIDPdd',
                                  component: () => import('../views/student/creativecenter/PIDPddTutorial'),
                                  meta: {
                                      title: '获取拼多多PID'
                                  }
                              },
                              {
                                  path: '/student/productPromotion/PIDJd',
                                  name: 'studentProductPromotionPIDJd',
                                  component: () => import('../views/student/creativecenter/PIDJdTutorial'),
                                  meta: {
                                      title: '获取京东PID'
                                  }
                              },
                              {
                                  path: '/student/productPromotion/index1',
                                  name: 'studentProductPromotionIndex1',
                                  component: () => import('../views/student/creativecenter/ProductPromotion1'),
                                  meta: {
                                      title: '商品推广旧版'
                                  }
                              },
                              {
                                  path: '/student/mySelectionLibrary',
                                  name: 'studentMySelectionLibrary',
                                  component: () => import('../views/student/creativecenter/MySelectionLibrary'),
                                  meta: {
                                      title: '我的选品库'
                                  }
                              },
                              {
                                  path: '/student/immediatePromotion',
                                  name: 'studentImmediatePromotion',
                                  component: () => import('../views/student/creativecenter/ImmediatePromotion'),
                                  meta: {
                                      title: '立即推广'
                                  }
                              },
                              {
                                  path: '/student/collectCoupons',
                                  name: 'studentCollectCoupons',
                                  component: () => import('../views/student/creativecenter/CollectCoupons'),
                                  meta: {
                                      title: '领优惠券'
                                  }
                              },
                          ]
                      },
                      {
                          path: '/student/script/creation/Index',
                          name: 'studentScriptCreationIndex',
                          component: StudentScriptLayout,
                          children: [
                              {
                                  path: '/student/script/creation',
                                  name: 'studentScriptCreation',
                                  component: () => import('../views/student/creativecenter/ScriptCreation'),
                                  meta: {
                                      title: '脚本创作'
                                  }
                              },
                              {
                                  path: '/student/script/view/creation',
                                  name: 'studentViewScriptCreation',
                                  component: () => import('../views/student/creativecenter/StudentViewScriptCreation'),
                                  meta: {
                                      title: '查看脚本'
                                  }
                              },
                          ]
                      },
                      {
                          path:'/student/works/announcement',
                          name:'studentWorksAnnouncement',
                          component:() => import('../views/student/creativecenter/AnnouncementDetail'),
                          meta: {
                              title:'平台公告'
                          }
                      },
                      {
                          path:'/student/works/helpcenter',
                          name:'studentWorksHelpCenter',
                          component:() => import('../views/student/creativecenter/HelpCenter'),
                          meta: {
                              title:'帮助中心'
                          }
                      }
                  ]
              },
              //任务中心
              {
                  path: '/student/task/index',
                  name: 'studentTask',
                  component: StudentShortLayout,
                  children: [
                      {
                          path: '/student/task/index',
                          name: 'studentTaskIndex',
                          component: () => import('../views/student/taskcenter/MissionHall'),
                          meta: {
                              title: '任务大厅'
                          }
                      },
                      {
                          path: '/student/task/detail',
                          name: 'studentTaskDetail',
                          component: () => import('../components/taskCenter/taskDetail/TaskDetail.vue'),
                          meta: {
                              title: '任务详情'
                          }
                      },
                      {
                          path:'/student/task/annexDetails',
                          name:'studenTaskDetailtAnnexDetails',
                          component: () => import('../components/taskCenter/taskDetail/AnnexDetails.vue'),
                          meta:{
                              title: '学生提交详情'
                          }
                      },
                      {
                          path:'/student/task/analysedetail',
                          name:'studenTaskDetailtAnalyseDetail',
                          component: () => import('../components/taskCenter/taskDetail/analyseDetail.vue'),
                          meta:{
                              title:'数据分析详情'
                          }
                      },
                      {
                          path: '/student/task/companyUserIndex',
                          name: 'studentCompanyUserIndex',
                          component: () => import('../components/taskCenter/CompanyUserIndex.vue'),
                          meta: {
                              title: '企业用户首页'
                          }
                      },
                      {
                          path: '/student/goods/index',
                          name: 'studentGoodsIndex',
                          component: () => import('../views/student/taskcenter/GoodsHall'),
                          meta: {
                              title: '商品大厅'
                          }
                      },
                  ]
              },
              //自媒体库
              {
                  path: '/student/hot/scripts',
                  name: 'studentHotScripts',
                  component: StudentShortLayout,
                  children: [
                      {
                          path: '/student/hot/scripts',
                          name: 'studentHot',
                          component: () => import('../views/student/medium/HotScripts'),
                          meta: {
                              title: '热门脚本'
                          }
                      },
                      {
                          path: '/student/hot/scriptsDetail',
                          name: 'studentHotScriptDetail',
                          component: () => import('../views/student/medium/HotScriptsDetail'),
                          meta: {
                              title: '热门脚本详情'
                          }
                      },
                      {
                          path: '/student/hot/topic',
                          name: 'studentHotTopic',
                          component: () => import('../views/student/medium/HotTopic'),
                          meta: {
                              title: '热门话题'
                          }
                      },
                      {
                          path: '/student/hot/articles',
                          name: 'studentHotArticles',
                          component: () => import('../views/student/medium/HotArticles'),
                          meta: {
                              title: '热门文章'
                          }
                      },
                      {
                          path: '/student/hot/broadcaster',
                          name: 'studentHotBroadcaster',
                          component: () => import('../views/student/medium/HotBroadcaster'),
                          meta: {
                              title: '热门播主'
                          }
                      },
                      {
                          path: '/student/hot/video',
                          name: 'studentHotVideo',
                          component: () => import('../views/student/medium/HotVideo'),
                          meta: {
                              title: '热门视频'
                          }
                      },
                      {
                          path: '/student/hot/videoDetail',
                          name: 'studentHotVideoDetail',
                          component: () => import('../views/student/medium/VideoDetail'),
                          meta: {
                              title: '视频详情'
                          }
                      },
                      {
                          path: '/student/hot/authorDetail',
                          name: 'studentAuthorDetail',
                          component: () => import('../views/student/medium/AuthorDetail'),
                          meta: {
                              title: '播主详情'
                          }
                      },
                      {
                          path: '/student/hot/authorVideoDetail',
                          name: 'studentAuthorVideoDetail',
                          component: () => import('../views/student/medium/AuthorVideoDetail'),
                          meta: {
                              title: '播主视频详情'
                          }
                      },
                  ]
              },
              //电商分析
              {
                  path: '/student/ds/video/ranking',
                  name: 'studentDsVideoRanking',
                  component: StudentShortLayout,
                  children: [
                      {
                          path: '/student/ds/video/rank',
                          name: 'studentDsVideo',
                          component: () => import('../views/student/ec/DsVideoRank'),
                          meta: {
                              title: '电商视频排行'
                          }
                      },{
                          path: '/student/ds/video/videodetail',
                          name: 'studentDsVideoDetail',
                          component: () => import('../views/student/ec/DsVideoDetail'),
                          meta: {
                              title: '视频详情'
                          }
                      },{
                          path: '/student/dy/products/rank',
                          name: 'studentDyProductsRank',
                          component: () => import('../views/student/ec/DyProductsRank'),
                          meta: {
                              title: '抖音商品排行'
                          }
                      },
                      {
                          path: '/student/tb/promotion/rank',
                          name: 'studentTbProductsRank',
                          component: () => import('../views/student/ec/TbProductsRank'),
                          meta: {
                              title: '淘宝推广排行'
                          }
                      },
                      {
                          path: '/student/tb/goodDetail',
                          name: 'studentTbGoodDetail',
                          component: () => import('../views/student/ec/GoodDetail'),
                          meta: {
                              title: '淘宝商品详情'
                          }
                      },
                      {
                          path: '/student/dy/goodDetail',
                          name: 'studentTbGoodDetail',
                          component: () => import('../views/student/ec/DYGoodDetail'),
                          meta: {
                              title: '抖音商品详情'
                          }
                      },
                      {
                          path: '/student/dy/favorite',
                          name: 'studentDyFavorite',
                          component: () => import('../views/student/ec/DyFavorite'),
                          meta: {
                              title: '抖音好物排行'
                          }
                      },
                      {
                          path: '/student/ds/sales/rank',
                          name: 'studentDsSalesRank',
                          component: () => import('../views/student/ec/DsSalesRank'),
                          meta: {
                              title: '电商达人销量排行'
                          }
                      },

                      {
                          path: '/student/ds/sales/rank/details',
                          name: 'studentDsSalesRankDetails',
                          component: () => import('../views/student/ec/DsSalesRankDetails'),
                          meta: {
                              title: '电商达人销量排行查看详情'
                          }
                      },
                      {
                          path: '/student/ds/video/details',
                          name: 'studentDsVideoDetails',
                          component: () => import('../views/student/ec/DsVideoDetails'),
                          meta: {
                              title: '视频详情'
                          }
                      },
                  ]
              },
              {
                  path:'/student/help/detail',
                  name:'studentHelpDetail',
                  component: () => import('../components/helpCenter/StudentHelpDetail.vue'),
                  meta: {
                          title: '帮助信息详情'
                  }
              },

            //   {
            //     path:'/TeacherLogin',
            //     name:'teacherLogin',
            //     component: () => import('./../views/TeacherLogin')
            //   }
              //个人中心

          ]
      },
      {
          path: '/teacher/shortteacher',
          component: TeacherLayout,
          name:'teacher',

          children:[
              {
                  path:'/teacher/account/index',
                  name:'teacherAudit',
                  component:TeacherShortLayout,
                  children:[
                      {
                          path:'/teacher/account/index',
                          name:'teacherAccountIndex',
                          component: () => import('../views/teacher/audit/AccountAudit'),
                          meta:{
                              title:'账号审核'
                          }
                      }
                  ]
              },
              {
                  path:'/teacher/taskcenter/index',
                  name:'teacherManagement',
                  component:TeacherShortLayout,
                  children:[
                      {
                          path:'/teacher/taskcenter/index',
                          name:'taskCenterIndex',
                          component: () => import('../views/teacher/management/taskCenter'),
                          meta:{
                              title:'任务中心'
                          }
                      },
                      {
                          path:'/teacher/taskcenter/viewdetails',
                          name:'taskcenterViewDetails',
                          component: () => import('../views/teacher/management/viewDetails'),
                          meta:{
                              title: '查看详情'
                          }
                      },
                      {
                          path:'/teacher/taskcenter/annexdetails',
                          name:'taskcenterAnnexDetails',
                          component: () => import('../views/teacher/management/AnnexDetails'),
                          meta:{
                              title: '学生附件详情'
                          }
                      },
                      {
                          path:'/teacher/taskcenter/analysedetail',
                          name:'analyseDetail',
                          component: () => import('../views/teacher/management/analyseDetail'),
                          meta:{
                              title:'数据分析详情'
                          }
                      },
                      {
                          path:'/teacher/taskcenter/taskIndex',
                          name:'taskIndex',
                          component: () => import('../views/teacher/management/taskIndex'),
                          meta:{
                              title:'任务中心'
                          }
                      },
                  ]
              },
              {
                  path:'/teacher/studentmang/index',
                  name:'teacherStudentMang',
                  component:TeacherShortLayout,
                  children:[
                      {
                          path:'/teacher/studentmang/index',
                          name:'studentmangIndex',
                          component: () => import('../views/teacher/studentmang/student'),
                          meta:{
                              title:'学生'
                          }
                      },
                      {
                          path: '/teacher/data/details',
                          name: 'teacherStuDataDetails',
                          component: () => import('../views/teacher/studentmang/DataDetails'),
                          meta: {
                              title: '账号详情'
                          }
                      },
                      {
                          path: '/teacher/account/details',
                          name: 'teacherStuAccountDetails',
                          component: () => import('../views/teacher/studentmang/accountDetail'),
                          meta: {
                              title: '账号总览'
                          }
                      },
                  ]
              }
          ]
      },
      {
          path:'/company',
          redirect: '/company/shortcompany',
          component: CompanyLayout,
          name:'company',
          children:[
              {
                  path:'/company/taskcenter/index',
                  name:'companyTaskCenter',
                  component:CompanyShortLayout,
                  children:[
                      {
                          path: '/company/taskcenter/index/Index',
                          name: 'companyTaskCenterIndex',
                          component: CompanyViewLayout,
                          children: [
                              {
                                  path:'/company/taskcenter/index',
                                  name:'taskCenterIndex',
                                  component: () => import('../views/company/task/taskCenter'),
                                  meta:{
                                      title:'任务中心'
                                  }
                              },
                              {
                                  path:'/company/taskcenter/publish',
                                  name:'taskCenterPublish',
                                  component: () => import('../views/company/task/taskPublish'),
                                  meta:{
                                      title:'发布任务'
                                  }
                              },
                              {
                                  path:'/company/taskcenter/registList',
                                  name:'registList',
                                  component: () => import('../views/company/task/registList'),
                                  meta:{
                                      title:'报名列表'
                                  }
                              },
                              {
                                  path:'/company/taskcenter/worksList',
                                  name:'worksList',
                                  component: () => import('../views/company/task/worksList'),
                                  meta:{
                                      title:'作品列表'
                                  }
                              },
                              {
                                  path:'/company/taskcenter/worksDetail',
                                  name:'worksDetail',
                                  component: () => import('../views/company/task/worksDetails'),
                                  meta:{
                                      title:'作品列表-详情'
                                  }
                              },
                              {
                                  path:'/company/taskcenter/analyseDetail',
                                  name:'companyAnalyseDetail',
                                  component: () => import('../views/company/task/analyseDetail'),
                                  meta:{
                                      title:'数据分析详情'
                                  }
                              },
                          ]
                      },
                      {
                          path: '/company/StuExpert/index/Index',
                          name: 'companyStuExpertIndex',
                          component: CompanyViewLayout,
                          children: [
                              {
                                  path:'/company/StuExpert/index',
                                  name:'StuExpertIndex',
                                  component: () => import('../views/company/product/StuExpert'),
                                  meta:{
                                      title:'学生达人榜'
                                  }
                              },
                              {
                                  path: '/company/data/picDetails',
                                  name: 'companyPicDetails',
                                  component: () => import('../views/company/expertDetail/picDataDetails'),
                                  meta: {
                                      title: '学生数据详情'
                                  }
                              },
                              {
                                  path: '/company/data/details',
                                  name: 'companyStuDataDetails',
                                  component: () => import('../views/company/expertDetail/videoDetails'),
                                  meta: {
                                      title: '账号详情'
                                  }
                              },
                          ]
                      },
                      {
                          path: '/company/product/index/Index',
                          name: 'companyProductIndex',
                          component: CompanyViewLayout,
                          children: [
                              {
                                  path:'/company/product/index',
                                  name:'productIndex',
                                  component: () => import('../views/company/product/productList'),
                                  meta:{
                                      title:'商品列表'
                                  }
                              },
                              {
                                  path:'/company/product/add',
                                  name:'addProduct',
                                  component: () => import('../views/company/product/addProducts'),
                                  meta:{
                                      title:'添加商品'
                                  }
                              },
                          ]
                      },
                      {
                          path:'/company/introduce/index',
                          name:'companyIntroIndex',
                          component: () => import('../views/company/product/companyIntro'),
                          meta:{
                              title:'企业介绍'
                          }
                      },
                  ]
              },

          ]
      }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})

export default router

<template>
    <div class="admin-header">
        <div class="header">
            <div class="header-logo">
<!--                <img src="../../assets/img/image/logo-blue.png" alt="" style="width: 140px">-->
                <img src="../../assets/logo1.png" alt="" style="width: 40px;">
                <span style="font-size: 16px; margin-left: 5px;color: #4154ea">新媒体实战平台</span>
            </div>
            <div class="nav-wrapper">
                <el-menu :default-active="schoolTab" class="nav" mode="horizontal">
                    <el-menu-item index="schoolEducation">
                        <router-link class="nav-item" to="/school/education/index">教务管理</router-link>
                    </el-menu-item>
                    <el-menu-item>
                        <router-link target="_blank" class="nav-item" to="/data/spectaculars">数据看板</router-link>
                    </el-menu-item>
                </el-menu>
                <div class="user-info">
                    <div class="user-avatar">
                        <!--<img src="../../assets/img/ceshi/acfun.png" alt="">-->
                        <img :src="userIcon" alt="">
                    </div>
                    <span class="user-name text-overflow">{{username}}</span>
                    <span class="top-sign-out" @click="logout">退出</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SchoolHeaderModule",
        data() {
            return {
                schoolTab: '',
                username: '',
                userIcon:require('../../assets/img/image/user_img.png'),
            }
        },
        mounted(){
            this.username = localStorage.getItem('username');
            if (!this.username) {
                this.$router.push('/admin/login');
            };
            let userIcon = localStorage.getItem('adminIcon');
            if (userIcon != '' && userIcon != undefined) {
                this.userIcon = userIcon;
            };
            this.schoolTab = this.$route.matched[1].name;
        },
        methods: {
            logout() {
                this.$http.axiosGet(this.$api.loginout, (res) => {
                    // console.log(res,'123')
                    if (res.code === 200) {
                        localStorage.clear();
                        this.$router.push('/admin/login');
                    }else if (res.code === 204){
                        localStorage.clear();
                        this.$router.push('/admin/login');
                    }
                },(err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .nav {
        flex: 1;
        width: 1%;
        border-bottom: none;
        .el-menu-item {
            padding: 0;
            .nav-item {
                display: block;
                padding: 0 20px;
            }
        }
    }
    .top-sign-out{
        cursor: pointer;
    }
</style>
<template>
    <div class="admin-content">
        <div class="left-wrapper">
<!--            <div class="left-title">商品管理</div>-->
            <el-menu :default-active="adminTab" class="left-nav">
<!--                <el-menu-item index="adminGoodsManagePromote">-->
<!--                    <router-link class="nav-item" to="/admin/goodsManage/promote">商品推广</router-link>-->
<!--                </el-menu-item>-->
                <el-menu-item index="adminGoodsManageList">
                    <router-link class="nav-item" to="/admin/goodsManage/list">商品列表</router-link>
                </el-menu-item>
            </el-menu>
        </div>
        <router-view class="goodsManage-container"/>
    </div>
</template>

<script>
    export default {
        name: "AdminTaskLayout",
        data() {
            return {
                adminTab: '',
            }
        },
        created() {
            this.adminTab = this.$route.meta.tabName;
        }
    }
</script>

<style scoped lang="scss">
    .left-title {
        font-weight: 500;
        font-size: 18px;
        padding: 20px 20px 20px 22px;
    }
    .left-nav {
        .el-menu-item {
            padding: 0!important;
            height: 46px;
            line-height:46px;
            border-left: 2px solid transparent;
            .nav-item {
                padding: 0 20px;
                display: block;
                color: #333;
            }
            &.is-active {
                height:46px;
                line-height:46px;
                .nav-item {
                    color: #1E34E4;
                }
            }
            &.is-active:after{
                position: absolute;
                content: '';
                height: 14px;
                width: 3px;
                background: #1E34E4;
                top: 16px;
            }
            &.el-menu-item:focus, &.el-menu-item:hover {
                outline: 0;
                background-color: transparent;
            }

        }
    }
    .goodsManage-container {
        width: 1%;
        flex: 1;
        background: #fff;
        border-radius: 8px;
    }
</style>
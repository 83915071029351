<template>
    <div class="student-content">
        <div class="left-wrapper">
            <el-scrollbar class="class-list" style="height: 100%" :native="false">
                <el-row class="tac">
                    <el-col :span="12">
                        <el-menu
                                :default-active="default_active"
                                class="el-menu-vertical-demo"
                                router
                                @open="handleOpen"
                                @close="handleClose">
                            <el-menu-item index="/company/StuExpert/index" @click="menuClick($event)">
                                <span slot="title">学生达人榜</span>
                            </el-menu-item>
                            <el-menu-item index="/company/taskcenter/index" @click="menuClick($event)">
                                <span slot="title">任务中心</span>
                            </el-menu-item>
                            <el-menu-item index="/company/product/index" @click="menuClick($event)">
                                <span slot="title">商品列表</span>
                            </el-menu-item>
                            <el-menu-item index="/company/introduce/index" @click="menuClick($event)">
                                <span slot="title">企业介绍</span>
                            </el-menu-item>
                        </el-menu>
                    </el-col>
                </el-row>
            </el-scrollbar>
        </div>
        <el-scrollbar class="right-wrapper" :native="false">
            <!--        <div class="right-wrapper">-->
            <div class="right-wrapper-content">
                <router-view @changeMenu="changeMenu"/>
            </div>
            <!--        </div>-->
        </el-scrollbar>
    </div>
</template>

<script>
    export default {
        name: "CompanyShortLayout",
        data() {
            return {
                default_active: "companyTask",
            }
        },
        created() {
            let index = localStorage.getItem('defaultActive');
            if (index) {
                this.default_active = index;
            }
        },
        methods: {
            menuClick(e) {
                localStorage.setItem('defaultActive', e.index);
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            changeMenu(val) {
                console.log(val)
                this.default_active = val;
            }
        }
    }
</script>

<style scoped lang="scss">
    .left-wrapper {
        .class-list {
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        ::v-deep .el-menu {
            background-color: transparent;
            border-right: none;
        }

        ::v-deep .el-col-12 {
            width: 100%;
        }

        ::v-deep .el-submenu__title {
            color: #fff;

            i {
                margin-right: 18px;
                width: 24px;
                text-align: center;
                vertical-align: middle;
                -webkit-text-fill-color: #fff;
            }

            &:hover {
                background-color: transparent;
            }
        }

        ::v-deep .el-menu-item {
            color: #fff;
        }

        .el-menu-item:hover {
            background-color: #464D77 !important;
        }

        ::v-deep .el-menu li.el-menu-item-group ul li.el-menu-item {
            background-color: #464d77;
            border-left: 2px solid transparent;
            color: #fff;
        }

        ::v-deep .el-submenu .el-menu-item {
            padding-left: 44px;
        }

        .el-menu-item.is-active {
            background-color: #5e668b;
            color: #fff;
            border-left: 2px solid #20c997;
        }

        ::v-deep a:focus, a:hover {
            color: #fff;
        }

        .nav-item {
            padding: 0 20px;
            display: block;
            color: #fff;
        }

        .work-title {
            height: 56px;
            line-height: 56px;
            position: relative;
            -webkit-box-sizing: border-box;
            white-space: nowrap;
            list-style: none;
            padding-left: 20px;
            font-size: 14px;
            cursor: pointer;
            transition: border-color .3s, background-color .3s, color .3s;
            box-sizing: border-box;
            color: #fff;
        }
    }

    .student-content {
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        .left-wrapper {
            width: 240px;
            background-color: #3c4167;
            height: 100%;
        }

        ::v-deep .right-wrapper {
            width: 1%;
            flex: 1;
            //padding: 20px;
            //overflow: auto;
            & > .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            & > .el-scrollbar__wrap > .el-scrollbar__view {
                min-width: 1000px;
            }

            .right-wrapper-content {
                padding: 20px;
            }
        }

        .main-wrapper {
            background: #fff;
            border-radius: 4px;
            height: 100%;
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            box-sizing: border-box;
        }
    }
</style>
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
    export default {
        name: 'App'
    }
</script>

<style lang="scss">
  #app , body {
    height: 100%;
    margin: 0;
  }
</style>

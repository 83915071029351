<template>
    <router-view />
</template>

<script>
    export default {
        name: "AdminTrainViewLayout",
        mounted() {
            console.log('123',this.$route)
        }
    }
</script>

<style scoped>

</style>
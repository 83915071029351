<template>
    <div class="admin-container">
        <TeacherHeaderModule></TeacherHeaderModule>
        <div class="student-wrapper">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import TeacherHeaderModule from '../components/header/TeacherHeaderModule'
    export default {
        name: "TeacherLayout",
        components: {
            TeacherHeaderModule
        }
    }
</script>

<style scoped >

</style>
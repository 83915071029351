<template>
    <div class="student-header">
        <div class="student-avatar-logo">
                <span class="student-menu">
                    <img src="../../assets/logo1.png">
                </span>
            <span class="student-avatar-title">新媒体实战平台</span>
        </div>
        <div class="student-left">
            <div class="student-avatar-user">
                <span class="student-avatar">
                    <img :src="userIcon" alt="">
                     <span class="user-name text-overflow">{{username}}</span>
                 </span>
<!--                <span class="student-icon">-->
<!--                    <i class="iconfont">&#xe629;</i>-->
<!--                    <span>设置</span>-->
<!--                </span>-->
                <span class="student-icon" @click="logout">
                    <i class="iconfont">&#xe88a;</i>
                    <span>退出</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CompanyHeaderModule",
        data() {
            return {
                username: '',
                userIcon: require('../../assets/img/image/user_img.png'),
            }
        },
        mounted() {
            this.username = localStorage.getItem('username');
            if (!this.username) {
                this.$router.push('/company/login');
            }
            ;
            let userIcon = localStorage.getItem('adminIcon');
            if (userIcon != '' && userIcon != undefined) {
                this.userIcon = userIcon;
            }
            ;
        },
        methods: {
            logout() {
                this.$httpcompany.axiosGet(this.$api.loginout, (res) => {
                    console.log(res)
                    if (res.code === 200) {
                        localStorage.clear();
                        this.$router.push('/company/login');
                    } else if (res.code === 204) {

                        localStorage.clear();
                        this.$router.push('/company/login');
                    }
                }, (err) => {
                    console.log(err);
                })
            }

        }
    }
</script>

<style scoped lang="scss">
    .student-header {
        width: 100%;
        height: 60px;
        /*margin: 0 auto;*/
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: start;

        .student-avatar-logo {
            width: 240px;
            height: 60px;
            background: #3C4167;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: #333;
                font-size: 14px;
                height: auto;
                display: flex;
                align-items: center;
                margin-right: 4px;
            }

            .student-menu {
                img {
                    width: 40px;
                    height: 30px;
                    /*border-radius: 50%;*/
                    cursor: pointer;
                }
            }

            .student-avatar-title {
                font-size: 18px;
                font-weight: 300;
                display: inline-block;
                color: #fff;
            }
        }

        .student-left {
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex: 1;
            height: 60px;
            box-shadow: 0px 0px 2px 0px rgba(51, 51, 51, 0.12);

            .student-avatar-user {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin-right: 20px;

                .student-avatar {
                    display: flex;
                    height: 32px;
                    line-height: 32px;
                    margin-right: 10px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        border-radius: 50%;
                        margin-right: 8px
                    }

                    .student-name {
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .student-icon {
                    display: flex;
                    line-height: 24px;
                    margin-right: 10px;

                    & i {
                        color: #9d9ea7;
                        font-size: 24px;
                        margin-right: 4px;
                    }

                    &:hover.student-icon {
                        color: #409eff;
                    }

                    &:hover.student-icon i {
                        color: #409eff;
                    }
                }
            }
        }
    }
</style>